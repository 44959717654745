<script>

import axios from "axios";
import {VueFinalModal} from "vue-final-modal";
import moment from "moment/moment";


export default {
  name: "file-picker",
  components: {VueFinalModal},
  data(){
    return {
      files:[],
      showM:false,
      root_path:[], // current path
      path_history:[],
      path_arr:[],
      mkdir_visible:false,
      new_dir_name:'',
      upload_input_visible:false
    }
  },
  methods:{
    open(){
      this.showM = true
      this.loadFiles()
    },

    loadFiles(){
      axios.get('/oj/uploads/filepicker_files.json').then(r=>{
        this.files = r.data.files
        this.root_path = this.files
      })
    },

    dir_list(){
      let arr=[]
      this.root_path.forEach(f=>{
        if (typeof(f) == 'object' && !f.size){
          arr.push(f.name)
        }
      })
      return arr
    },
    file_list(){
      let arr=[]
      this.root_path.forEach(f=>{
        if (f.size && f.name){
          arr.push(f)
        }
      })
      return arr
    },
    cd_dir(d){
      this.path_arr.push(d)
      let nd= this.root_path.find(f=>f.name==d).dir
      this.path_history.push(this.root_path)
      this.root_path = nd
    },
    dir_up(){
      // o jednu uroven smerem ke koreni
      this.root_path = this.path_history.pop()
      this.path_arr.pop()
    },
    cd_home(){
      this.root_path = this.files
      this.path_history = []
      this.path_arr = []
    },
    hideModal(){
      this.showM = false
    },
    select_file(f) {



      const path= this.path_arr.join('/')+'/'+f.name
      console.log('select_file',f, path)
      this.$emit('file-selected', `/galleries/${path}`)
      this.showM = false
    },
    format_filesize(size) {
      if (size < 1024) return size + ' B'
      if (size < 1024 * 1024) return (size / 1024).toFixed(2) + ' KB'
      return (size / 1024 / 1024).toFixed(2) + ' MB'

    },
    file_thumb_ico(f){
      if (f.thumb) return f.thumb
      if (f.name.match(/\.mp4$/) || f.name.match(/\.MP4$/)  ) return 'https://editor.onlinejazyky.cz/media/glyphicons/glyphicons_180_facetime_video.png'
      // https://editor.onlinejazyky.cz/media/glyphicons/glyphicons_180_facetime_video.png
    },
    mkdir(){
      console.log('mkdir', this.new_dir_name)
      axios.post('/oj/uploads/mkdir.json', {name: this.new_dir_name, path: this.path_arr.join('/') }).then(r=>{

        // pridat do seznamu
        this.root_path.push({name: this.new_dir_name, dir:[]})
        this.mkdir_visible = false
        this.new_dir_name = ''
      })

    }
    ,
    open_input_mkdir() {
      this.mkdir_visible = !this.mkdir_visible
    },
    open_upload_input(){
      this.upload_input_visible = !this.upload_input_visible
      document.getElementById('fileinput').click()
    },
    uploadFile(){
      let formData = new FormData()
      console.log("uploadFile", this.path_arr)

      let file_for_upload = document.getElementById('fileinput').files[0]
      formData.append('file', file_for_upload)
      formData.append('path', this.path_arr.join('/'))
      axios.post('/oj/uploads/upload.json', formData).then(r=>{
        console.log('uploaded', r.data.file)
        this.root_path.push({ name:r.data.file, size: file_for_upload.size, thumb: null})
      })
    }

  }
}
</script>

<template>
   <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w600 zuperz">
      <div class="modal-header">
        <b>Galerie souborů</b>
        <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>


     <div class="d-flex">
       <div>
       <a class="cursor-pointer" @click.prevent="open_input_mkdir">vytvořit složku</a>
       <span v-if="mkdir_visible"> název: <input type="text" v-model="new_dir_name"/><button @click.prevent="mkdir">OK</button></span>
       </div>

       <div class="ml-auto">
       <a class="cursor-pointer" @click.prevent="open_upload_input">nahrát soubor</a>
        <span style="visibility: hidden">
          <input type="file" @change="uploadFile" id="fileinput" />
        </span>
       </div>
     </div>


     <div v-if="root_path!=this.files" class="dir-nav">
       <img :src="`/img/icons/user-home.svg`" @click.prevent="cd_home()" width="40" height="40" class="cursor-pointer"/>
       <span>{{path_arr.join('/')}}</span>
       <button  class="btn2 ml-3 " @click.prevent="dir_up()"><b>&uparrow;</b></button>
     </div>
     <div class="scrolframe">

     <table class="table table-striped">
      <tr v-for="d in dir_list()" @click.prevent="cd_dir(d)" class="dir-item folder">
        <td colspan="2"><img src="https://editor.onlinejazyky.cz/media/glyphicons/glyphicons_144_folder_open.png"> {{ d }}</td>

      </tr>


      <tr v-for="f in file_list()" class="dir-item file">
        <td><a @click.prevent="select_file(f)">
            <img :src="file_thumb_ico(f)"> {{ f.name }} </a></td>
        <td>{{format_filesize(f.size)}}</td>
      </tr>

     </table>





     </div>
      <div>




      </div>

    </vue-final-modal>
</template>

<style scoped lang="scss">

.dir-item{


  padding: 5px;
  cursor: pointer;
  img{
    margin-right: 10px;
  }
  &.file{

  }
}

.dir-nav{
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: #f0f0f0;
  border-bottom: 2px solid #ccc;
  span{
    margin-left: 10px;
  }
}


.scrolframe{
  max-height: 500px;
  overflow-y: auto;
}

</style>