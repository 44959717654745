<script>
import {VueFinalModal} from "vue-final-modal";
import ModalKkMixin from "@/components/conversation-club/modal-kk-mixin.js";
import ImagePicker from "@/components/oj/image-picker.vue";
import axios from "axios";
import {toast} from "vue3-toastify";

export default {
  components: {VueFinalModal, ImagePicker},
  mixins: [ModalKkMixin],
  props:['week'],
  name: "week-form",
  data(){
    return {
      showM: false,
      image: null,
    }
  },
  methods:{
    onImageChange(f){
      this.image = f
    },
    handleSaveChanges() {
      /*const formData = new FormData()
      formData.append('week[name]', this.week.name)
      if (this.image) {
        formData.append("week[image]", this.image, this.image.name)
      }

*/
      const formData={
        week:{
          name: this.week.name,
          image: this.image
        }
      }

      if (this.week.id) {

        axios.put(`/oj/weeks/${this.week.id}`, formData).then(response => {
          this.$emit('weekUpdated')
          this.hideModal()
        })
      }else{
        axios.post(`/oj/chapters/${this.week.chapter_id}/weeks`, formData).then(response => {
          this.$emit('weekUpdated')
          this.hideModal()
        }).catch(e=>{

          toast.error(e.response.data.errors)

        })

      }
    }
  }
}
</script>

<template>
  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w800">
    <div class="modal-header">
      <b>Lekce</b>
      <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal__content">

      <div class="form-group">
        <label for="name">Name</label>
        <input type="text" class="form-control" id="name" v-model="week.name">
      </div>

      <div class="form-group">
        <label>Obrázek</label>
        <image-picker @imageChange="onImageChange" :src="week.image"></image-picker>

      </div>



    </div>
    <div class="modal-footer">
      <button @click="handleSaveChanges">Uložit změny</button>
    </div>

  </vue-final-modal>




</template>

<style scoped>

</style>