<script>
import FilePicker from "./file-picker.vue";
export default {
  name: "image-picker",
  props: ['src'],
  components: {FilePicker},
  data(){
    return {
      base_url: 'https://editor.onlinejazyky.cz',
      imgsrc: this.src ? `https://editor.onlinejazyky.cz${this.src}`: null,
    }
  },
  watch:{
    src(){
      this.imgsrc = `${this.base_url}${this.src}`
    }
  },
  methods:{

    handleImageSelected(f){
      console.log('handle image selected', f)
      this.base_url = ''
      this.$emit('imageChange', f)

      this.imgsrc = f
    }
  },
}
</script>

<template>
  <img :src="imgsrc" class="form-image"/>
  <button @click="$refs.impfilepicker.open()">nahrát obrázek</button>
  <!--<input type="file" name="add-file-ico" class="hidden" v-on:change="handleUploadImage($event)"
         ref="pinimagefile">-->
  <FilePicker @file-selected="handleImageSelected" ref="impfilepicker" />
</template>

<style scoped>
.form-image{
  max-width: 200px;
  display: block;
  margin: auto;
}

</style>