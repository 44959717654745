<script>
import axios from 'axios'
import ChapterForm from "@/components/oj/courses/chapter-form.vue";
import WeekForm from "@/components/oj/courses/week-form.vue";
import draggable from 'vuedraggable'
import {toast} from "vue3-toastify";
import WeekSortOrder from "@/components/oj/courses/week-sort-order.vue";
export default {
  name: "structure",
  props:['course'],
  components: {ChapterForm, WeekForm, draggable, WeekSortOrder},
  data(){
    return {
      drag:false,

      chapters:[],
      current_chapter:{},
      current_week:{},
    }
  },
  methods:{

    run_kurz_duplikator(){
      axios.post('/oj/courses/duplicate',{id:this.course.id}).then(r=>{
        console.log(r)
        toast.success('Kopírování kurzu se spustilo, po nějakém čase se objěví v seznamu.')
      }).catch(e=>{
        console.log(e)
        toast.error('Nepodařilo se duplikovat kurz' + e)
      })
    },


    loadChapters(){
      if (!this.course.id) return
      axios.get('/oj/courses/'+this.course.id+'/chapters.json').then(r=>{
        this.chapters = r.data.chapters
      })
    }
    ,
    handleEditChapter(chapter){
      this.$emit('edit-chapter',chapter)
      this.current_chapter = chapter
      this.$refs.modalChapter.showModal()

    },
    handleEditWeek(week){
      this.current_week = week
      this.$refs.modalWeek.showModal()
      this.$emit('edit-week',week)
    },
    editWeekLessons(week){
      this.current_week = week
      this.$emit('editWeekLessons',week)
    },

    handleNewChapter(){
      this.current_chapter = {course_id:this.course.id}
      this.$refs.modalChapter.showModal()
    },
    handleChapterUpdated(){
      this.loadChapters()
    },

    newWeekDialogOpen(chapter){
      this.current_week = {
        chapter_id:chapter.id
      }
      this.$refs.modalWeek.showModal()
    },
    weekUpdated(){
      this.loadChapters()
    },
    drag_end(a){
      //  put 'chapters/:course_id/reorder' => 'chapters#reorder'

      this.drag=false
      let ids = this.chapters.map(c=>c.id)
      axios.put(`/oj/chapters/${this.course.id}/reorder`,{ids:ids}).then(r=>{
        console.log(r)
        toast.success('Pořadí kapitol bylo uloženo')
      }).catch(e=>{
        console.log(e)
        toast.error('Nepodařilo se uložit pořadí kapitol' + e)
      })


    },
    openSortWeekDialog(ch){
      this.current_chapter = ch
      this.$refs.modalWeekSortOrder.showModal()
    }



  },
  mounted(){
    this.loadChapters()
  },
  watch:{
    course(){
      this.loadChapters()
    }
  }
}
/* chapter:   {
    "id": 577,
    "course_id": 62,
    "name": "1. Travelling",
    "author_id": 56,
    "approver_id": null,
    "created": "2012-08-08T13:53:15.000+02:00",
    "approved": null,
    "is_approved": false,
    "is_exam": false,
    "lesson_id": 1823,
    "image": "/galleries/users/56/Pixmac000083946937.jpg",
    "description": null,
    "is_last_in_course": false,
    "order": null,
    "adaptive_exercise_count": null,
    "adaptive_exercise_success": null,
    "is_active": 1,
    "allow_feedback": 1
  }

 */
</script>

<template>
  <h3><span class="course_id">({{course.id}})</span> {{course.name}}</h3>
  <div class="row">
    <div class="col-md-6"><button @click.prevent="handleNewChapter" >nova kapitola</button></div>
    <div class="col-md-2 ml-auto text-right">
      <button @click.prevent="run_kurz_duplikator">Duplikovat kurz</button>
    </div>
  </div>

<!--  chapters: -->

  <table>
    <tbody>
      <draggable v-model="chapters" item-key="id"
             @start="drag=true"
              @end="drag_end"
      >
         <template #item="{ element }">
      <tr  class="chapter" >
        <td class="images">

          <img :src="`https://editor.onlinejazyky.cz${element.image}`" alt="chapter.name" width="100" v-if="element.image"/>
          <div v-else class="nahrada-za-obrazek"></div>

        </td>
        <td>
          <h3><a href="#" @click.prevent="handleEditChapter(element)">{{element.name}}</a></h3>

        </td>
        <td>
          <div v-for="week in element.weeks">

            <router-link  :to="{ name: 'lessons', params: { cid: course.id, wid: week.id }}">

              {{week.name}}
            </router-link>

            &nbsp;<a :href="`#week${week.id}`" @click.prevent="handleEditWeek(week)"><span class="material-icons-outlined">mode_edit_outline</span></a>
          </div>
          <div>
            <button @click.prevent="newWeekDialogOpen(element)">Přidat lekci</button>
            <button @click.prevent="openSortWeekDialog(element)">Upravit pořadí</button>
          </div>
        </td>
      </tr>
         </template>
      </draggable>

    </tbody>
  </table>


  <chapter-form :chapter="current_chapter" ref="modalChapter" @chapterUpdated="handleChapterUpdated"></chapter-form>
  <week-form :week="current_week" ref="modalWeek" @weekUpdated="weekUpdated"  ></week-form>
  <lessons :week="current_week"></lessons>
  <week-sort-order :chapter="current_chapter" ref="modalWeekSortOrder"></week-sort-order>
</template>

<style scoped lang="scss">

tr.chapter{
  border:1px solid #ccc;
  margin:10px;
  padding:10px;
  td{
    padding:10px;
    vertical-align: middle;
    h3{
      margin:0 20px;
    }
  }
}

.nahrada-za-obrazek{
  width:100px;
  height:100px;
  background-color: #eee;
}

td.images{
  padding:10px;
}


.course_id{
  font-size: 0.8em;
  color: #999;
}
</style>