<script>

import AppTable from "../form-generator/AppTable.vue";
import axios from "axios";
import {VueFinalModal} from "vue-final-modal";


export default {
  name: "konverzky-project-options",
  components: {AppTable, VueFinalModal},
  data() {
    return ({

      brands: ['oj', 'el', 'el_cz'],
      langs:[],



      fields: [
        {
          "label": "Projekt",
          "type": "text",
          "codename": "konverzky_project_id"
        }, {
          "label": "Nazev",
          "type": "text",
          "codename": "name"
        },
        { label: 'Brand', codename: "brand" }
      ],
      filters:false,
      app: {
        data_url: '/konverzky_projects.json',
        destroy_url: '/konverzky_projects/:id',
        actions: "delete",
        filter_default_hidden: false,

      },

      showModal: false,
      item: {},


    })
  },
  mounted() {
    this.loadLanguages()
  },
  methods:{
    loadLanguages(){
      axios.get('/languages.json',{}).then(response =>{
        this.langs = response.data.languages
      })
    },
    handleItemClick(item){
      this.item = {
        name: item.name,
        konverzky_project_id: item.konverzky_project_id,
        brand: item.brand,
        language_id: item.language_id,
        id: item.id,
        default_password: item.default_password
      }
      this.showModal = true

    },
    new_project() {
      this.item = {
        name: '',
        konverzky_project_id: '',
        brand: '',
        language_id: '',
        id: null
      }
      this.showModal = true
    }

    ,
    handleSaveChanges(){
      if (this.item.id>0){

        axios.put(`/konverzky_projects/${this.item.id}`, {konverzky_project: this.item}).then(response =>{
          this.showModal = false
          this.$refs.app_table.loadItems()
        })

      } else {

        axios.post('/konverzky_projects', {konverzky_project: this.item}).then(response => {
          this.showModal = false
          this.$refs.app_table.loadItems()
        })

      }
    }

  } // M
}
</script>

<template>
  <div class="card card-body index ">

    <h1>Nastavení pro Konverzky/projekty </h1>
    <div>
      <button @click="new_project">Přidat projekt</button>
    </div>


      <AppTable :app="app"
                :fields="fields"
                :filters="filters"
                @itemClick="handleItemClick"
                ref="app_table"></AppTable>


    <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content-f mcw1">
      <div class="modal-header">
        <span class="modal__title fg-modal-title">Data {{item.id}}</span>
        <button type="button" class="close" @click.prevent="showModal=false" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal__content" >
        <div class="form-group">
          <label> Nazev </label>
          <input type="text" v-model="item.name" class="form-control"/>
        </div>

        <div class="form-group">
            <label>Projekt</label>
            <input type="text" v-model="item.konverzky_project_id" class="form-control"/>
        </div>


        <div class="form-group">
            <label>Brand</label>
            <select v-model="item.brand" class="form-control">
                <option v-for="brand in brands" :value="brand" >{{brand}}</option>
            </select>
        </div>

        <div class="form-group">
            <label>Jazyk</label>
            <select v-model="item.language_id" class="form-control">
                <option v-for="lang in langs" :value="lang.id" >{{lang.name}}</option>
            </select>
        </div>


        <div class="form-group">
          <label>Výchozí heslo</label>
          <input type="text" v-model="item.default_password" class="form-control"/>
        </div>

        <div>
          <button class="btn btn-success" @click.prevent="handleSaveChanges">Uložit změny</button>
        </div>

      </div>

    </vue-final-modal>

    </div>

</template>

<style scoped>

</style>