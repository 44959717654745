import "vue-multiselect/dist/vue-multiselect.min.css"
import MassCoursesAssignment from "@/components/mass-courses-assignment.vue";
import OjMultiselect from "@/components/oj-multiselect.vue";
import SelectStudent from "@/components/select-student.vue";
import Gift_vouchers from "@/components/tables/gift_vouchers.vue";

import {add_vueApp2} from "@/packs/vue_helper.js";
import OrganizationGroupSupervisorsComponent from "@/components/organization/organization-group-supervisors-component.vue";

import KonverzkyProjectOptions from "./tables/konverzky-project-options.vue";

add_vueApp2('organization-group-supervisors-component', OrganizationGroupSupervisorsComponent)

add_vueApp2('component-oj-multiselect-courses', OjMultiselect)
add_vueApp2("component-oj-multiselect-products", OjMultiselect)
add_vueApp2('component-courses-multiselect-category', OjMultiselect)
add_vueApp2('gift_vouchers_app', Gift_vouchers)
add_vueApp2('konverzky-project-options', KonverzkyProjectOptions)

document.addEventListener('DOMContentLoaded', () => {
    const elements = document.querySelectorAll('.oj-multiselect-component');
    elements.forEach((element) => {
        add_vueApp2(element.id, OjMultiselect);
    });


    add_vueApp2('app-mass-assignment-courses-group', MassCoursesAssignment)
})


add_vueApp2('component-select-student', SelectStudent)